require('./bootstrap');

$(document).ready(function () {
    //logout
    const logoutBtn = $(".js-user-logout");
    const logoutForm = $("#logout-form");
    logoutBtn.on("click", function (e) {
        e.preventDefault();
        logoutForm.submit();
    });

    //filter box open
    const filtersTrigger = $(".js-filters-toggle");
    const filtersContainer = $(".js-filters-container");
    const filtersClose = $(".js-filters-close");

    filtersTrigger.on("click", function () {
        // $(this).slideUp("slow");
        filtersContainer.slideToggle("slow");
    });

    filtersClose.on("click", function () {
        // filtersTrigger.slideDown("fast");
        filtersContainer.slideUp("fast");
    });

    $('[data-toggle="tooltip"]').tooltip({
        container: "body",
        boundary: "window",
    });

    //main menu
    const menuOpen = $(".js-menu-open");
    const menuClose = $(".js-menu-close");
    const menu = $(".js-main-menu");

    menuOpen.on("click", function () {
        menu.addClass("active");
    });

    menuClose.on("click", function () {
        menu.removeClass("active");
    });

    //Initialize Select2 Elements
    $(".js-select2").select2({
        theme: "bootstrap4",
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
    });

    $("[data-mask]").inputmask();

    //Date range picker
    $(".js-date-picker").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
            format: "DD/MM/YYYY",
        },
    });

    //Date range picker with time picker
    $(".js-datetime-picker").daterangepicker({
        singleDatePicker: true,
        timePicker: true,
        timePickerIncrement: 30,
        locale: {
            format: "DD/MM/YYYY HH:mm",
        },
    });

    $(".js-datetime-range-picker").daterangepicker({
        singleDatePicker: false,
        timePicker: true,
        timePickerIncrement: 30,
        locale: {
            format: "DD/MM/YYYY HH:mm",
        },
        maxDate: new Date()
    });

    //Date range picker
    $(".js-daterange-picker").daterangepicker({
        singleDatePicker: false,
        timePicker: false,
        locale: {
            format: "DD/MM/YYYY",
        },
    });

    // $("input[data-bootstrap-switch]").each(function(){
    //     $(this).bootstrapSwitch('state', $(this).prop('checked'));
    // });
});
